import axios from 'axios'
import { getCurrencyFullName } from '@/models/Currencies'
import { CURRENCY_SHORT_CODES, FIAT_CURRENCIES, ORDER_TYPE_NAMES } from '@/constants/auto-exchange'
import {
  getGlassAverageByAmount,
  makeDealsV3, makeDealsV4,
} from '@/utils/autoexchange'
import Decimal from "decimal.js";
import { deepCopy } from "@/utils/common";
import { CONSTRAINT_TYPE_NAMES } from '@/constants/common'

const state = () => ({
  ExchangeRateConstraints: [],
  AutoexchangeCurrenciesList: undefined,
  AutoexchangeCurrencyToPairs: undefined,
  selectedCurrency: 0,
  selectedPair: 0,
  currencies: [
    'RUB',
    'USD',
    'EUR',
    'Crypto'
  ],
  currencyPairs: [
    'BTC/RUB',
    'ETH/RUB',
    'DOGE/DOGE',
    'LTC/RUB',
    'BTC/RUB',
    'ETH/RUB'
  ],
  currencyFastFilterLastTrades: null,
  orderTypeFilterLastTrades: null,
  currencyFastFilterOpenOrders: null,
  orderTypeFilterOpenOrders: null,
  currencyFastFilterOrderHistory: null,
  orderTypeFilterOrderHistory: null,
  selectedOrderType: ORDER_TYPE_NAMES.MARKET,
  unprocessedOrders: [],
  lastTrades: [],
  orderHistory: [],
  currencySteps: [],
  userOffers: [],
  openSessionId: null,
  glassObject: {},
  userOffersByPair: {},
  ordersByPair: [],
  loadingOrdersByPair: false,
  aeRequestCommission: '0',
  accessObject: {}
})

const getters = {
  getCurrencySteps: state => state.currencySteps.reduce((acc, step) => {
    acc[CURRENCY_SHORT_CODES[step.currency_type]] = +step.step
    return acc
  }, {}),
  sellGlassAverageAmount: (state, getters) => (amount, total, exchangeRateConstraint) => {
    if (!getters.buyOrders?.length) return ''
    if (!getters.glassBuyRates?.length) return ''
    if (!+amount && !+total) return ''
    const value = amount || total
    const orderAmountProp = !!amount ? 'firstAmount' : 'secondAmount'
    // const orderAmountProp = !!amount ? 'first_amount' : 'second_amount'
    const decimalPlaces = (exchangeRateConstraint || getters.getCurrentExchangeRateConstraint).step.split('.')[1]?.length || 0
    return getGlassAverageByAmount(value, getters.localMatchedOrders?.buyOrders, orderAmountProp, decimalPlaces)
    // return getGlassAverageByAmountV2(value, getters.glassResult?.buy, orderAmountProp, getters.getCurrentExchangeRateConstraint.decimal_places)
  },
  buyGlassAverageAmount: (state, getters) => (amount, total, exchangeRateConstraint) => {
    if (!getters.sellOrders?.length) return ''
    // if (!getters.glassSellRates?.length) return ''
    if (!+amount && !+total) return ''
    const value = amount || total
    const orderAmountProp = !!amount ? 'firstAmount' : 'secondAmount'
    // const orderAmountProp = !!amount ? 'first_amount' : 'second_amount'
    const reversedArray = [...getters.localMatchedOrders?.sellOrders].reverse()
    // const reversedArray = [...getters.glassResult?.sell].reverse()
    const decimalPlaces = (exchangeRateConstraint || getters.getCurrentExchangeRateConstraint).step.split('.')[1]?.length || 0
    return getGlassAverageByAmount(value, reversedArray, orderAmountProp, decimalPlaces)
    // return getGlassAverageByAmountV2(value, reversedArray, orderAmountProp, getters.getCurrentExchangeRateConstraint.decimal_places)
  },
  lastTrades: (state, getters) => state.lastTrades.filter(order => {
    return CURRENCY_SHORT_CODES[order.first_currency] === getters.pairFirstCurrencyName &&
      CURRENCY_SHORT_CODES[order.second_currency] === getters.pairSecondCurrencyName
  }).sort((a, b) => b.timestamp - a.timestamp),
  orderHistoryFilters: state => Array.from(state.orderHistory.reduce((acc, order) => {
    const { first_currency, second_currency } = order
    acc.add(CURRENCY_SHORT_CODES[first_currency])
    acc.add(CURRENCY_SHORT_CODES[second_currency])
    return acc
  }, new Set())),
  orderHistory: state => state.orderHistory.sort((a, b) => b.timestamp - a.timestamp),
  userOrdersFilters: state => Array.from(state.userOffers.reduce((acc, order) => {
    const { first_currency, second_currency } = order
    acc.add(CURRENCY_SHORT_CODES[first_currency])
    acc.add(CURRENCY_SHORT_CODES[second_currency])
    return acc
  }, new Set())),
  userOrders: state => state.userOffers.sort((a, b) => b.timestamp - a.timestamp),
  sellOrders: (state, getters) => {
    // return getters.glassOrders.filter(item => item.type === 'sell').sort((a, b) => b.exchange_rate - a.exchange_rate)
    const rateObject = getters.glassResult?.sell || {}
    // const rateObject = getters.glassOrders?.sell || {}
    return Object.keys(rateObject)
      .reduce((acc, exchangeRate) => {
        if (!!Number(rateObject[exchangeRate])) {
          acc.push({
            exchangeRate,
            firstAmount: rateObject[exchangeRate],
            secondAmount: new Decimal(exchangeRate).mul(rateObject[exchangeRate]).toFixed()
          })
        }
        return acc
      }, [])
      .sort((a, b) => b.exchangeRate - a.exchangeRate)
  },
  buyOrders: (state, getters) => {
    // return getters.glassOrders.filter(item => item.type === 'buy').sort((a, b) => b.exchange_rate - a.exchange_rate)
    const rateObject = getters.glassResult?.buy || {}
    // const rateObject = getters.glassOrders?.buy || {}
    return Object.keys(rateObject)
      .reduce((acc, exchangeRate) => {
        if (!!Number(rateObject[exchangeRate])) {
          acc.push({
            exchangeRate,
            firstAmount: rateObject[exchangeRate],
            secondAmount: new Decimal(exchangeRate).mul(rateObject[exchangeRate]).toFixed()
          })
        }
        return acc
      }, [])
      .sort((a, b) => b.exchangeRate - a.exchangeRate)
  },
  localMatchedOrders: (state, getters) => {
    const noSomeOrders = !getters.sellOrders?.length || !getters.buyOrders?.length
    const maxSellOrdersIdx = getters.sellOrders?.length - 1
    const minSellRate = getters.sellOrders[maxSellOrdersIdx]?.exchangeRate || 0
    let maxBuyRate = getters.buyOrders[0]?.exchangeRate || 0
    // Проверяем нужно ли вообще что-то матчить
    if (new Decimal(minSellRate).gt(maxBuyRate) || noSomeOrders) {
      return {
        buyOrders: getters.buyOrders,
        sellOrders: getters.sellOrders
      }
    }
    let buyOrders = []
    let sellOrders = []
    let sellIdxWithNotValidRate = null
    // Получаем индекс заявки на продажу, у которой курс ниже,
    // чем максимальный курс в заявках на покупку
    for (let sellIdx = maxSellOrdersIdx || 0; sellIdx > -1; sellIdx--) {
      const sellOrder = getters.sellOrders[sellIdx]
      if (new Decimal(sellOrder?.exchangeRate || 0).gt(maxBuyRate)) break
      sellIdxWithNotValidRate = sellIdx
    }
    // Массив заявок, у которых курс ниже, чем максимальный курс на продажу
    let sellOrdersForMatch = deepCopy(getters.sellOrders.slice(sellIdxWithNotValidRate))
    // Индекс последней заявки на продажу для матчинга
    let lastSellOrderForMatchIdx = sellOrdersForMatch.length - 1
    // Индекс последней заявки на покупку для матчинга
    let lastBuyOrderMatchedIdx = null
    // Текущая заявка на покупку
    let buyOrder = null
    // Цикл по заявкам на покупку
    for (let buyIdx = 0; buyIdx < getters.buyOrders?.length; buyIdx++) {
      const sellOrder = sellOrdersForMatch[lastSellOrderForMatchIdx]
      buyOrder = buyOrder || deepCopy(getters.buyOrders[buyIdx])
      // Если нет заявки на продажу для матчинга или
      // если максимальный курс buyOrders меньше,
      // чем минимальный курс sellOrders, то выходим
      if (!sellOrder || new Decimal(buyOrder.exchangeRate).lt(sellOrder.exchangeRate)) {
        lastBuyOrderMatchedIdx = buyIdx
        buyOrders = [buyOrder, ...getters.buyOrders.slice(buyIdx + 1)]
        break
      }
      // Проверяем, если максимальный курс buyOrders меньше,
      // чем минимальный курс sellOrders, то заканчиваем матчинг
      const buyFirstAmount = new Decimal(buyOrder.firstAmount)
      const buySecondAmount = new Decimal(buyOrder.secondAmount)
      const sellFirstAmount = new Decimal(sellOrder.firstAmount)
      // если заявки матчатся в ноль, то переходим к следующей заявке на продажу
      if (buyOrder.firstAmount === sellOrder.firstAmount) lastSellOrderForMatchIdx -= 1
      buyOrder.firstAmount = buyFirstAmount.minus(sellOrder.firstAmount)
      buyOrder.secondAmount = buySecondAmount.minus(sellFirstAmount.mul(buyOrder.exchangeRate))
      // Если заявка на продажу сматчилась полностью, а на покупку - нет
      if (buyOrder.firstAmount.gt(0)) {
        buyOrder.firstAmount = buyOrder.firstAmount.toFixed()
        buyOrder.secondAmount = buyOrder.secondAmount.toFixed()
        // Переходим к следующей заявке на продажу
        lastSellOrderForMatchIdx -= 1
        // Запоминаем текущую заявку на покупку
        buyIdx -= 1
        continue
      }
      sellOrder.firstAmount = buyOrder.firstAmount.abs().toFixed()
      sellOrder.secondAmount = buyOrder.secondAmount.abs().toFixed()
      buyOrder = null
    }
    sellOrders = [...getters.sellOrders.slice(0, sellIdxWithNotValidRate), ...sellOrdersForMatch.slice(0, lastSellOrderForMatchIdx + 1)]
    return {
      buyOrders,
      sellOrders
    }
    // Цикл в обратную сторону по ордерам на продажу
    // for (let sellIdx = maxSellOrdersIdx || 0; sellIdx > -1; sellIdx--) {
    //   // Получаем либо уже откорректированный ордер на продажу,
    //   // либо делаем копию ордера из базового массива
    //   const sellOrder = sellOrders[sellIdx] || deepCopy(getters.sellOrders[sellIdx])
    //   // Проверяем нужно ли дальше матчить
    //   if (new Decimal(sellOrder?.exchangeRate || 0).gt(maxBuyRate)) break
    //   for (let buyIdx = 0; buyIdx < getters.buyOrders?.length; buyIdx++) {
    //     // Получаем либо уже откорректированный ордер на покупку,
    //     // либо делаем копию ордера из базового массива
    //     const buyOrder = buyOrders[buyIdx] || deepCopy(getters.buyOrders[buyIdx])
    //     const buyFirstAmount = new Decimal(buyOrder.firstAmount)
    //     const buySecondAmount = new Decimal(buyOrder.secondAmount)
    //     const sellFirstAmount = new Decimal(sellOrder.firstAmount)
    //     buyOrder.firstAmount = buyFirstAmount.minus(sellOrder.firstAmount)
    //     buyOrder.secondAmount = buySecondAmount.minus(sellFirstAmount.mul(buyOrder.exchangeRate))
    //     // Сматчился ли ордер на продажу?
    //     if (buyOrder.firstAmount.gt(0)) {
    //       const _sellIdx = sellIdx === 0 ? sellIdx + 1 : sellIdx
    //       // sellOrders = getters.sellOrders.slice(0, _sellIdx)
    //       sellOrders = getters.sellOrders.slice(0, sellIdx)
    //       buyOrder.firstAmount = buyOrder.firstAmount.toFixed()
    //       buyOrder.secondAmount = buyOrder.secondAmount.toFixed()
    //       buyOrders = [buyOrder, ...getters.buyOrders.slice(buyIdx + 1)]
    //       break
    //     } else {
    //       sellOrder.firstAmount = buyOrder.firstAmount.abs().toFixed()
    //       sellOrder.secondAmount = buyOrder.secondAmount.abs().toFixed()
    //       // Обновляем максимальный курс ордеров на продажу,
    //       // так как считаем, что текущий сматчился
    //       maxBuyRate = getters.buyOrders[buyIdx + 1]?.exchangeRate || 0 // ERROR
    //       // Проверяем нужно ли дальше матчить
    //       if (new Decimal(sellOrder?.exchangeRate || 0).gt(maxBuyRate)) {
    //         sellOrders = getters.sellOrders.slice(0, sellIdx)
    //         const { firstAmount } = sellOrder
    //         // Проверяем остался ли объем в заявке на продажу
    //         // и нужно ли его добавлять в результирующий массив
    //         if (+firstAmount) sellOrders.push(sellOrder)
    //         buyOrders = getters.buyOrders.slice(buyIdx + 1)
    //         break
    //       }
    //     }
    //   }
    // }
  },
  glassOrders: (state, getters) => {
    // return state.unprocessedOrders.filter(item => {
    //   const _firstCurr = CURRENCY_SHORT_CODES.getKeyByValue(getters.pairFirstCurrencyName)
    //   const _secondCurr = CURRENCY_SHORT_CODES.getKeyByValue(getters.pairSecondCurrencyName)
    //   const { first_currency, second_currency } = item
    //   return _firstCurr === first_currency && _secondCurr === second_currency
    // })
    return state.unprocessedOrders[getters.selectedPairName]
  },
  // glass v2
  glassMatchedOrders: (state, getters) => {
    // const orders = state.glassObject?.[getters.selectedPairName] || []
    const orders = state.loadingOrdersByPair ? [] : state.ordersByPair || []
    const decimalPlacesFa = Number(getters.getProcessCurrencyObj('AUTOEXCHANGE', CURRENCY_SHORT_CODES[orders[0]?.first_currency])?.truncate_precision) || 2
    const decimalPlacesSa = Number(getters.getProcessCurrencyObj('AUTOEXCHANGE', CURRENCY_SHORT_CODES[orders[0]?.second_currency])?.truncate_precision) || 2
    return makeDealsV3(orders, decimalPlacesFa, decimalPlacesSa)
  },
  glassResult: (state, getters) => {
    const resultObj = {
      sell: {},
      buy: {}
    }
    getters.glassMatchedOrders.forEach(order => {
      const orderType = order.type
      if (resultObj[orderType][order.exchange_rate]) {
        resultObj[orderType][order.exchange_rate] = new Decimal(resultObj[orderType][order.exchange_rate]).plus(order.first_amount).toFixed()
      } else {
        resultObj[orderType][order.exchange_rate] = order.first_amount
      }
    })
    return resultObj
  },
  glassSellRates: (state, getters) => {
    return Object.keys(getters.glassResult.sell).sort((a, b) => b - a)
  },
  glassBuyRates: (state, getters) => {
    return Object.keys(getters.glassResult.buy).sort((a, b) => b - a)
  },

  isMarketOrderType: state => state.selectedOrderType === ORDER_TYPE_NAMES.MARKET,
  currenciesForSelect: () => [...FIAT_CURRENCIES, 'Crypto'],
  currencyPairsForSelect: (state, getters) => {
    return getters.pairsForAutoexchange.filter(item => {
      const mainCondition = getters.selectedCurrencyName === 'Crypto'
        ? !FIAT_CURRENCIES.some(currency => item.split('/').includes(currency))
        : item.split('/').includes(getters.selectedCurrencyName)
      const accessCondition = state.accessObject[item].canBuy && state.accessObject[item].canSell
      return mainCondition && accessCondition
    })
  },
  currenciesForFilter: (state) => {
    return state.AutoexchangeCurrenciesList?.map(item => CURRENCY_SHORT_CODES[item]) || []
  },
  pairFirstCurrencyName: (state, getters) => getters.selectedPairName?.split('/')[0],
  pairSecondCurrencyName: (state, getters) => getters.selectedPairName?.split('/')[1],
  buyBalanceAmount: (state, getters) => {
    return getters.GetBalance?.[getters.pairSecondCurrencyName] || 0
  },
  sellBalanceAmount: (state, getters) => {
    return getters.GetBalance?.[getters.pairFirstCurrencyName] || 0
  },
  selectedCurrencyName: (state, getters) => getters.currenciesForSelect[state.selectedCurrency],
  selectedPairName: (state, getters) => getters.currencyPairsForSelect[state.selectedPair],
  GetExchangeRateConstraint: state => (currency1, currency2) => {
    currency1 = getCurrencyFullName(currency1)
    currency2 = getCurrencyFullName(currency2)
    for (let constraint of state.ExchangeRateConstraints) {
      if ((constraint.first_currency === currency1 && constraint.second_currency === currency2) ||
        (constraint.first_currency === currency2 && constraint.second_currency === currency1)) {
        return constraint
      }
    }
    return undefined
  },
  getExchangeRateConstraint: state => (currency1, currency2) => {
    const c1 = CURRENCY_SHORT_CODES.getKeyByValue(currency1)
    const c2 = CURRENCY_SHORT_CODES.getKeyByValue(currency2)
    return state.ExchangeRateConstraints?.find(constraint => {
      return (constraint.first_currency === c1 && constraint.second_currency === c2) ||
        (constraint.first_currency === c2 && constraint.second_currency === c1)
    })
  },
  getCurrentExchangeRateConstraint: (state, getters) => {
    // return getters.getExchangeRateConstraint(getters.pairSecondCurrencyName, getters.pairFirstCurrencyName) || {}
    const pairCode = `${getters.pairFirstCurrencyName}/${getters.pairSecondCurrencyName}`
    return getters.pairsForAutoexchangeMap?.[pairCode] || {}
  },
  GetAutoexchangeCurrenciesList: state => state.AutoexchangeCurrenciesList,
  GetAutoexchangePairsForCurrency: state => currency => {
    return state.AutoexchangeCurrencyToPairs.get(currency)
  },
  pairsForAutoexchange: state => state.ExchangeRateConstraints?.map(item => {
    return `${CURRENCY_SHORT_CODES[item.first_currency]}/${CURRENCY_SHORT_CODES[item.second_currency]}`
  }) || [],
  pairsForAutoexchangeMap: state => {
    return state.ExchangeRateConstraints?.reduce((acc, item) => {
      const pairCode = `${CURRENCY_SHORT_CODES[item.first_currency]}/${CURRENCY_SHORT_CODES[item.second_currency]}`
      acc[pairCode] = item
      return acc
    }, {}) || {}
  },
  getAutoExchangeConstraint: (state, getters, rootState) => rootState.ProcessCurrency.constraints?.[CONSTRAINT_TYPE_NAMES.AUTOEXCHANGE] || {},
  firstCurrencyDecimalPlaces: (state, getters, rootState) => {
    const currencyObj = getters.getAutoExchangeConstraint[getters.pairFirstCurrencyName]
    if (!currencyObj) return 0
    return +currencyObj.truncate_precision
    // if (!rootState.ProcessCurrency.operationLimitations.get(getters.pairFirstCurrencyName)) return 0
    // return +rootState.ProcessCurrency.operationLimitations.get(getters.pairFirstCurrencyName).truncate_precision
  },
  secondCurrencyDecimalPlaces: (state, getters) => {
    const currencyObj = getters.getAutoExchangeConstraint[getters.pairSecondCurrencyName]
    if (!currencyObj) return 0
    return +currencyObj.truncate_precision
    // if (!rootState.ProcessCurrency.operationLimitations.get(getters.pairSecondCurrencyName)) return 0
    // return +rootState.ProcessCurrency.operationLimitations.get(getters.pairSecondCurrencyName).truncate_precision
  },
  getOpenSessionId: state => state.openSessionId,
  getUserOffersByCurrentPair: (state, getters) => state.userOffersByPair[getters.selectedPairName]
}

const mutations = {
  setAeRequestCommission(state, val) {
    state.aeRequestCommission = val
  },
  setLoadingOrdersByPair(state, val) {
    state.loadingOrdersByPair = val
  },
  setOrdersByPair(state, val) {
    state.ordersByPair = val
  },
  setCurrencySteps(state, val) {
    state.currencySteps = val
  },
  setUserOffers(state, val) {
    state.userOffers = val
  },
  setLastTrades(state, val) {
    state.lastTrades = val
  },
  setOrderHistory(state, val) {
    state.orderHistory = val
  },
  setUnprocessedOrders(state, val) {
    state.unprocessedOrders = val
  },
  setSelectedOrderType(state, val) {
    state.selectedOrderType = val
  },
  setSelectedCurrency(state, val) {
    state.selectedCurrency = val
  },
  setSelectedPair(state, val) {
    state.selectedPair = val
  },
  SetExchangeRateConstraints: (state, val) => {
    state.ExchangeRateConstraints = val
  },
  SetAutoexchangeCurrenciesList: (state, val) => {
    state.AutoexchangeCurrenciesList = val
  },
  SetAutoexchangeCurrencyToPairs: (state, val) => {
    state.AutoexchangeCurrencyToPairs = val
  },
  setOpenSessionId: (state, val) => {
    state.openSessionId = val
  },
  setGlassObject: (state, val) => {
    state.glassObject = val
  },
  setUserOffersByPair: (state, val) => {
    state.userOffersByPair = val
  },
  updateGlassObject (state, { data, decimalPlacesFa }) {
    // const { first_currency, second_currency } = data
    // const firstCurrency = CURRENCY_SHORT_CODES[first_currency]
    // const secondCurrency = CURRENCY_SHORT_CODES[second_currency]
    // const pair = `${firstCurrency}/${secondCurrency}`
    const isSell = data.type === 'sell'
    const operationId = data.operation_id
    if (state.ordersByPair.find(i => i.operation_id === operationId)) return
    if (!isSell) {
      data.first_amount = new Decimal(data.second_amount).div(data.exchange_rate).toFixed(decimalPlacesFa, Decimal.ROUND_DOWN)
    }
    data.is_market_order = new Decimal(data.exchange_rate || 0).eq(0)
    state.ordersByPair.push(data)
    // if (state.glassObject[pair]) state.glassObject[pair].push(data)
    // else state.glassObject[pair] = [data]
  },
  setAccessObject (state, obj) { state.accessObject = obj }
}

// const offersMock111 = []
// let count = 0
// const rates = ['101', '', '102', '103', '104', '105', '107']
// const amounts = ['5', '7', '13', '12', '10']
// const types = ['sell', 'buy']
// const markets = ['1060', '670', '300']
// while (count < 1000) {
//   const tmp = {
//     "timestamp": 1699169103.5,
//     "type": "buy",
//     "first_amount": "12",
//     "second_amount": "1300",
//     "exchange_rate": "",
//     "is_market_order": true,
//     "canceled": false
//   }
//   const rateIdx = count % 7
//   // const rateIdx = Math.floor(Math.random() * 7)
//   const amountIdx = count % 5
//   // const amountIdx = Math.floor(Math.random() * 5)
//   const typeIdx = count % 2
//   // const typeIdx = Math.floor(Math.random() * 2)
//   const marketIdx = count % 3
//   tmp.timestamp = count
//   tmp.exchange_rate = rates[rateIdx]
//   tmp.first_amount = amounts[amountIdx]
//   // tmp.second_amount = new Decimal(tmp.exchange_rate).mul(tmp.first_amount).toFixed(2)
//   tmp.type = types[typeIdx]
//   tmp.is_market_order = !tmp.exchange_rate
//   if (!tmp.exchange_rate) {
//     if (tmp.type === 'sell') tmp.second_amount = ''
//     else {
//       tmp.second_amount = markets[marketIdx]
//       tmp.first_amount = ''
//     }
//   }
//   else tmp.second_amount = new Decimal(tmp.exchange_rate).mul(tmp.first_amount).toFixed(2)
//   offersMock111.push(tmp)
//   count++
// }
// console.log({ offersMock111 })

// const offersMock = [
//   {
//     "timestamp": 0,
//     "type": "sell",
//     "first_amount": "5",
//     "second_amount": "50",
//     "exchange_rate": "10",
//     "is_market_order": false,
//     "canceled": false
//   },
//   {
//     "timestamp": 1,
//     "type": "buy",
//     "first_amount": "5",
//     "second_amount": "60",
//     "exchange_rate": "12",
//     "is_market_order": false,
//     "canceled": false
//   },
// ]
//
// console.log('TEST', makeDealsV3(offersMock, '0.01', '0.01'))


const actions = {
  async fetchOrdersByPair ({ commit, rootState, getters }, selectedPairName) {
    commit('setLoadingOrdersByPair', true)
    try {
      const pairForSubscribe = (selectedPairName || getters.selectedPairName).split('/').map(i => CURRENCY_SHORT_CODES.getKeyByValue(i)).join('/')
      const response = await axios.get(`${rootState.backEndAddress}/autoexchange/offers/${pairForSubscribe}`, {
        params: {
          cancelled: false
        }
      })
      const { data } = response
      if (data?.status === 200) {
        data.payload?.sort((a, b) => {
          a.is_market_order = new Decimal(a.exchange_rate || 0).eq(0)
          b.is_market_order = new Decimal(b.exchange_rate || 0).eq(0)
          return a.timestamp - b.timestamp
        })
        commit('setOrdersByPair', data.payload)
      } else {
        throw Error('status not 200')
      }
    } catch (err) {
      throw Error(err)
    } finally {
      commit('setLoadingOrdersByPair', false)
    }
  },
  updateGlassObjectAction ({ state, getters, commit, dispatch }, data) {
    const decimalPlacesFa = Number(getters.getProcessCurrencyObj('AUTOEXCHANGE', CURRENCY_SHORT_CODES[data?.first_currency])?.truncate_precision) || 2
    commit('updateGlassObject', { data, decimalPlacesFa })
  },
  async fetchOpenSessionId ({ commit, rootState }) {
    try {
      const { data } = await axios.get(`${rootState.backEndAddress}/autoexchange/session/open/id`)
      if (data?.status === 200) {
        commit('setOpenSessionId', data.payload)
      } else throw Error('status not 200')
    } catch (e) {
      console.log('fetchOpenSessionId', e)
    }
  },
  async fetchCurrencySteps ({ commit, rootState }) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/autoexchange/currency-steps`)
      const { data } = response
      if (data?.status === 200) {
        commit('setCurrencySteps', data.payload)
        // commit('setCurrencySteps', [
        //   {
        //     currency_type: 'bitcoin',
        //     step: '0.00005'
        //   },
        //   {
        //     currency_type: 'dogecoin',
        //     step: '0.00004'
        //   }
        // ])
      } else throw Error('status not 200')
    } catch (e) {
      throw Error(e)
    }
  },
  async fetchOpenOrders ({ commit, rootState }) {
    try {
      const { data } = await axios.get(`${rootState.backEndAddress}/autoexchange/user-offers`, {
        params: {
          cancelled: false
        }
      })
      if (data?.status === 200) {
        const result = {}
        data.payload.forEach(order => {
          const {
            first_currency,
            second_currency,
          } = order
          const firstCurrency = CURRENCY_SHORT_CODES[first_currency]
          const secondCurrency = CURRENCY_SHORT_CODES[second_currency]
          const pair = `${firstCurrency}/${secondCurrency}`
          if (!result[pair]) result[pair] = []
          result[pair].push(order)
        })
        commit('setUserOffersByPair', result)
        commit('setUserOffers', data.payload)
      } else throw Error('status not 200 for fetching user orders')
    } catch (err) {
      throw Error(err)
    }
  },
  async fetchLastTrades ({ commit, rootState }) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/autoexchange/all-matches-impersonal`)
      const { data } = response
      if (data?.status === 200) {
        commit('setLastTrades', data.payload)
      } else throw Error('status not 200')
    } catch (e) {
      throw Error(e)
    }
  },
  async fetchOrderHistory ({ commit, rootState }) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/autoexchange/matches`)
      const { data } = response
      if (data?.status === 200) {
        commit('setOrderHistory', data.payload)
      } else throw Error('status not 200')
    } catch (e) {
      throw Error(e)
    }
  },
  async fetchUnprocessedOrders ({ commit, rootState }) {
    try {
      // const response = await axios.get(`${rootState.backEndAddress}/autoexchange/unprocessed`, {
      const response = await axios.get(`${rootState.backEndAddress}/autoexchange/offers`, {
        params: {
          cancelled: false
        }
      })
      const { data } = response
      if (data?.status === 200) {
        // const resultObj = {}
        const resultObjV2 = {}
        // const resultObjV3 = {}
        // const resultObjV4 = { buy: {}, sell: {} }
        // const sortedOffers = offersMock1.sort((a, b) => a.timestamp - b.timestamp)
        // sortedOffers.forEach(i => {
        //   // console.log(i.timestamp, i.exchange_rate, resultObjV2[i.exchange_rate])
        //   i = { type: i.type, timestamp: i.timestamp, exchange_rate: i.exchange_rate, first_amount: i.first_amount }
        //   // if (Object.keys(re))
        //   if (resultObjV2[i.exchange_rate]) {
        //     resultObjV2[i.exchange_rate].push(i)
        //     // if (resultObjV2[i.exchange_rate][i.type]) resultObjV2[i.exchange_rate][i.type].push(i)
        //     // else resultObjV2[i.exchange_rate][i.type] = [i]
        //   } else {
        //     const courses = Object.keys(resultObjV2).sort((a, b) => new Decimal(a).minus(b).toNumber())
        //     const reverseOrderType = i.type === 'sell' ? 'buy' : 'sell'
        //     const course = courses.find(c => new Decimal(i.exchange_rate).gte(c) && resultObjV2[c].some(i => i.type === reverseOrderType))
        //     // const course = courses.find(c => new Decimal(i.exchange_rate).gte(c))
        //     // console.log({ course })
        //     if (course) resultObjV2[course].push(i)
        //     else resultObjV2[i.exchange_rate] = [i]
        //     // resultObjV2[i.exchange_rate] = {}
        //     // resultObjV2[i.exchange_rate][i.type] = [i]
        //
        //     // resultObjV2[i.exchange_rate] = [i]
        //   }
        //   // if (resultObjV3[i.exchange_rate]) {
        //   //   if (resultObjV3[i.exchange_rate]?.[i.type]) resultObjV3[i.exchange_rate][i.type].push(i)
        //   //   else {
        //   //     resultObjV3[i.exchange_rate][i.type] = [i]
        //   //   }
        //   // } else {
        //   //   resultObjV3[i.exchange_rate] = { sell: [], buy: [] }
        //   //   resultObjV3[i.exchange_rate][i.type].push(i)
        //   // }
        //   if (resultObjV3[i.exchange_rate]) {
        //     if (resultObjV3[i.exchange_rate]?.[i.type]) resultObjV3[i.exchange_rate][i.type].push(i)
        //     else {
        //       resultObjV3[i.exchange_rate][i.type] = [i]
        //     }
        //   } else {
        //     resultObjV3[i.exchange_rate] = { sell: [], buy: [] }
        //     resultObjV3[i.exchange_rate][i.type].push(i)
        //   }
        //   const operator = i.type === 'sell' ? 'lte' : 'gte'
        //   const _courses = Object.keys(resultObjV4[i.type]).sort((a,b) => {
        //     return i.type === 'sell' ? a - b : b - a
        //     // return a - b
        //   })
        //   const _course = _courses.find(c => new Decimal(i.exchange_rate)[operator](c))
        //   // if (_course) {
        //   //   resultObjV4[i.type][_course].push(i)
        //   // } else {
        //   //   if (resultObjV4[i.type][i.exchange_rate]) resultObjV4[i.type][i.exchange_rate].push(i)
        //   //   else {
        //   //     // resultObjV4[i.type] = {}
        //   //     resultObjV4[i.type][i.exchange_rate] = [i]
        //   //   }
        //   // }
        //   if (resultObjV4[i.type][i.exchange_rate]) resultObjV4[i.type][i.exchange_rate].push(i)
        //   else {
        //     // resultObjV4[i.type] = {}
        //     resultObjV4[i.type][i.exchange_rate] = [i]
        //   }
        // })
        // const sortedOffers = data.payload.sort((a, b) => a.timestamp - b.timestamp)
        data.payload?.sort((a, b) => a.timestamp - b.timestamp)
        // offersMock1.sort((a, b) => a.timestamp - b.timestamp)
        data.payload.forEach(order => {
        // offersMock1.forEach(order => {
          const {
            first_currency,
            second_currency,
          } = order
          order.is_market_order = new Decimal(order.exchange_rate || 0).eq(0)
          const firstCurrency = CURRENCY_SHORT_CODES[first_currency]
          const secondCurrency = CURRENCY_SHORT_CODES[second_currency]
          const pair = `${firstCurrency}/${secondCurrency}`
          if (!resultObjV2[pair]) resultObjV2[pair] = [order]
          else resultObjV2[pair].push(order)
        })
        // console.log({ resultObjV2 })
        commit('setGlassObject', resultObjV2)
        // commit('setUnprocessedOrders', resultObj)
        // commit('setUnprocessedOrders', data.payload)
      } else {
        throw Error('status not 200')
      }
      return response
    } catch (e) {
      console.log('UNPROCESSED ERROR', { e })
      throw Error(e)
    }
  },
  getExchangeRateConstraints (context) {
    return axios({
      method: 'GET',
      url: `${context.rootState.backEndAddress}/autoexchange/exchange-rate-constraints`
    })
      .then(response => {
        if (response.data.status === 200) {
          let ercs = response.data.payload
          context.commit('SetExchangeRateConstraints', ercs)
          let currSet = new Set()
          let currToPairs = new Map()
          const accessObject = {}

          ercs.forEach(e => {
            currSet.add(e.first_currency)
            currSet.add(e.second_currency)
            currToPairs.has(e.first_currency) ? currToPairs.get(e.first_currency).push(e.second_currency) : currToPairs.set(e.first_currency, [e.second_currency])
            currToPairs.has(e.second_currency) ? currToPairs.get(e.second_currency).push(e.first_currency) : currToPairs.set(e.second_currency, [e.first_currency])
            const { users_can_buy: canBuy, users_can_sell: canSell, first_currency, second_currency } = e
            const pairName = `${CURRENCY_SHORT_CODES[first_currency]}/${CURRENCY_SHORT_CODES[second_currency]}`
            if (!canBuy?.length && !canSell?.length) {
              accessObject[pairName] = { canSell: true, canBuy: true }
            } else {
              const userId = localStorage.getItem('user_id')
              accessObject[pairName] = { canBuy: !canBuy?.length || canBuy?.includes(userId), canSell: !canSell?.length || canSell?.includes(userId) }
            }
          })
          context.commit('SetAutoexchangeCurrenciesList', Array.from(currSet))
          context.commit('SetAutoexchangeCurrencyToPairs', currToPairs)
          context.commit('setAccessObject', accessObject)
        } else {
          throw new Error('Can\'t get exchange rate constraints')
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespace: false
}
