import axios from 'axios'
import Store from '@/store'
import { AddBitcoinExternalWallet } from '@/Methods/AddBitcoinExternalWallet'
import { getCurrencyFullName } from '../models/Currencies'
// import { currencies } from '@/models/currencies'

const actions = {
  createNewWallet: (context, dataForSend) => {
    return AddBitcoinExternalWallet(dataForSend)
      .then(response => {
        return response
      })
      .then(sendingData => {
        let currencyFullName = getCurrencyFullName(dataForSend.currency)
        return axios({
          method: 'POST',
          url: `${Store.state.backEndAddress}/wallets/accounts/${currencyFullName}`,
          data: sendingData,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'ru-RU'
          }
        })
          .then(response => {
            return response
          })
      })
      .catch(err => {
        return err
      })
  }
}

export default {
  actions
}
