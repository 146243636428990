import { app as Vue } from '@/entry-files/main'
import { createRouter, createWebHistory } from 'vue-router'

const getStaticFile = (path) => {
  const link = document.createElement('a')
  link.href = path
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: Home,
    component: () => import(/* webpackChunkName: "landingPage" */ '../views/index2.vue'),
    beforeEnter: (to, from, next) => {
      if (isAndroidApp) next('/lk/login')
      else next()
    }
  },
  {
    path: '/paymentServices',
    name: 'paymentServices',
    component: () => import(/* webpackChunkName: "paymentServices" */ '../views/infoPage/paymentServices.vue')
  },
  {
    path: '/useCases',
    name: 'useCases',
    component: () => import(/* webpackChunkName: "useCases" */ '../views/infoPage/useCases.vue')
  },
  {
    path: '/USProposition',
    name: 'USProposition',
    component: () => import(/* webpackChunkName: "USProposition" */ '../views/infoPage/USProposition.vue')
  },
  {
    path: '/cloudEconomy',
    name: 'cloudEconomy',
    component: () => import(/* webpackChunkName: "cloudEconomy" */ '../views/infoPage/cloudEconomy.vue')
  },
  {
    path: '/integration',
    name: 'integration',
    component: () => import(/* webpackChunkName: "integration" */ '../views/infoPage/integration.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/infoPage/about.vue')
  },
  {
    path: '/ComingSoon',
    name: 'ComingSoon',
    component: () => import(/* webpackChunkName: "comingsoon" */ '../views/infoPage/ComingSoon.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faqpage" */ '../views/infoPage/faq.vue')
  },
  {
    path: '/faq/ios',
    name: 'faqIos',
    component: () => import(/* webpackChunkName: "faqiospage" */ '../views/infoPage/faqIos.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import(/* webpackChunkName: "video" */ '../views/infoPage/video.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import(/* webpackChunkName: "documents" */ '../views/infoPage/documents.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '../views/infoPage/support.vue')
  },
  {
    path: '/LionERC',
    name: 'LionERC',
    component: () => import(/* webpackChunkName: "LionERC" */ '../views/infoPage/LionERC20.vue')
  },
  {
    path: '/tariffs',
    name: 'tariffs',
    component: () => import(/* webpackChunkName: "tariffs" */ '../views/infoPage/Tariffs.vue')
  },
  {
    path: '/airdrop',
    name: 'airdrop',
    component: () => import(/* webpackChunkName: "airdrop" */ '../views/infoPage/AirDrop.vue')
  },
  {
    path: '/ibmaward',
    name: 'ibmaward',
    component: () => import(/* webpackChunkName: "airdrop" */ '../views/infoPage/ibmaward.vue')
  },
  // {
  //   path: '/referral-program',
  //   name: '/referral-program',
  //   component: () => import(/* webpackChunkName: "AirDropInstructions" */ '../views/infoPage/AirDropInstructions')
  // },
  {
    path: '/license',
    name: 'license',
    component: () => import(/* webpackChunkName: "license" */ '../views/infoPage/License.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/infoPage/News.vue')
  },
  {
    path: '/press',
    name: 'press',
    component: () => import(/* webpackChunkName: "press" */ '../views/infoPage/Press.vue')
  },
  {
    path: '/AirDropReport',
    name: 'AirDropReport',
    component: () => import(/* webpackChunkName: "AirDropReport" */ '../views/infoPage/AirDropReport.vue')
  },
  {
    path: '/ibm_cloud',
    name: 'ibm_cloud',
    component: () => import(/* webpackChunkName: "ibm_cloud" */ '../views/infoPage/IbmCloud.vue')
  },
  {
    path: '/market-expansion-offering',
    name: 'tokenSale',
    component: () => import(/* webpackChunkName: "tokenSale" */ '../views/infoPage/tokenSale.vue')
  },
  {
    path: '/coinmarketcap/hc/en-us/requests/new',
    name: 'tokenSale',
    component: () => import(/* webpackChunkName: "tokenSale" */ '../views/infoPage/CoinMarketCupApplication.vue')
  },
  {
    path: '/email-subscription',
    name: 'emailSubscription',
    component: () => import(/* webpackChunkName: "emailSubscription" */ '../views/SubscriptionConfirmation.vue')
  },
  {
    path: '/rules',
    name: 'termsOfUse',
    beforeEnter (to, from, next) {
      const enLocalePrefix = localStorage.getItem('locale') === 'en' ? '_en' : ''
      getStaticFile(`/terms_of_use${enLocalePrefix}.pdf`)
      next('/')
    },
  },
  {
    path: '/personal-information',
    name: 'persInfo',
    beforeEnter (to, from, next) {
      getStaticFile('/personal_information.pdf')
      next('/')
    },
  },
  {
    path: '/confidential-policy',
    name: 'persInfoPolicy',
    beforeEnter (to, from, next) {
      getStaticFile('/personal_information_policy.pdf')
      next('/')
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { left: 0, top: 0 }
    }
  },
  routes,
})

router.afterEach((to, from) => {
  Vue.sessionRefresh()
  if (to.hash) {
    return { selector: to.hash }
  } else {
    return { left: 0, top: 0 }
  }
})
router.beforeEach((to, from, next) => {
  if (to.href.includes('lk') && to.path !== from.path) window.location.href = to.path
  else next()
})
export default router
