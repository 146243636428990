import axios from "axios";

const state = () => ({
  matchingSuspended: false
})

const mutations = {
  setMatchingSuspended (state, val) {
    state.matchingSuspended = val
  }
}

const actions = {
  saveAdminItem ({ state, rootState }, { apiMethod, data }) {
    const reqObj = {
      method: 'POST',
      url: `${rootState.backEndAddress}${apiMethod}`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept-Language': 'ru-RU'
      }
    }
    if (data) reqObj.data = JSON.stringify(data)
    return axios(reqObj)
  },
  async fetchMatchingStatus ({ commit, rootState }) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/admin/is-matching-suspended`)
      if (response?.data?.status === 200) {
        commit('setMatchingSuspended', response.data.payload)
      }
    } catch (err) {
      console.log({ err })
    }
  },
  async fetchUser ({ rootState }, userId) {
    try {
      const response = await axios.get(`${rootState.backEndAddress}/user/${userId}`)
      if (response?.data?.status === 200) {
        return response.data.payload
      }
    } catch (err) {
      console.log({ err })
      return null
    }
  }
}

const getters = {
  isMatchingSuspended: state => state.matchingSuspended
}

export default {
  state,
  mutations,
  actions,
  getters
}
