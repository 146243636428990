export default {
  state: () => ({
    visibleCashierForm: false
  }),
  mutations: {
    setVisibleCashierForm (state, value) {
      state.visibleCashierForm = value
    },
  },
  actions: {},
  getters: {
    showCashierForm: state => state.visibleCashierForm
  }
}
