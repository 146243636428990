export default {
  state: () => ({
    cashierInfo: {},
    showCashierForm: false
  }),
  mutations: {
    setCashierInfo (state, payload) {
      state.cashierInfo = payload
    },
    setShowCashierForm (state, val = false) {
      state.showCashierForm = val
    }
  },
  actions: {},
  getters: {
    cashierId: state => state.cashierInfo.cashierId,
    inputAccount: state => state.cashierInfo.inputAccount,
    inputWallet: state => state.cashierInfo.inputWallet,
    outputAccount: state => state.cashierInfo.outputAccount,
    outputWallet: state => state.cashierInfo.outputWallet,
    showCashExchangePopup: state => state.showCashierForm
  }
}
